import gql from 'graphql-tag';

export const reconnectToken = gql`
  mutation reconnectToken($orgId: ID!, $connectionId: ID!) {
    reconnectToken(orgId: $orgId, connectionId: $connectionId) {
      success
      error
    }
  }
`;

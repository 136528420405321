<template>
  <ba-dialog
    :show="dialog"
    v-on:show-dialog="dialog = true"
    :title="title"
    :onHide="hideDialog"
    :btnName="$t(btnName)"
    :btnOutline="btnOutline"
    :btnClass="btnClass"
    :btnColor="btnColor"
    :btnIcon="btnIcon"
  >
    <v-container fluid grid-list-xl>
      <v-alert type="error" :value="errors.length > 0" v-for="error in errors" :key="error.message">
        {{ error.message }}
      </v-alert>
      <v-layout wrap align-center v-if="connectionData">
        <v-flex xs12>
          <v-alert type="info" :value="connectionData.accountCode">
            Previous Values: <b>{{ readPreviousValues() }}</b>
          </v-alert>
        </v-flex>
      </v-layout>
      <v-layout wrap align-center>
        <v-flex xs6>
          <v-select
            :label="$t('_bankAccount') + '*'"
            :items="assetAccounts"
            v-model="preferredBankAccount"
            item-text="name"
            item-value="id"
            :menu-props="{ contentClass: 'first-element-bold' }"
          ></v-select>
        </v-flex>
        <v-flex xs6>
          <v-text-field v-model="newAccountCode" v-if="source === 'Xero'" label="Account Code"></v-text-field>
        </v-flex>
        <v-flex xs6>
          <v-select
            :label="$t('_expenseAccount') + '*'"
            :items="expenseAccounts"
            v-model="preferredExpenseAccount"
            item-text="name"
            item-value="id"
            :menu-props="{ contentClass: 'first-element-bold' }"
          ></v-select>
        </v-flex>
        <v-flex xs6>
          <v-text-field v-model="newFeeAccountCode" v-if="source === 'Xero'" label="Fee Account Code"></v-text-field>
        </v-flex>
      </v-layout>
      <v-flex xs12 sm12 md6>
        <v-btn @click="save" :disabled="setingUpConnection || !isValid" color="primary text-capitalize">{{
          $t('_save')
        }}</v-btn>
      </v-flex>
    </v-container>
  </ba-dialog>
</template>
<script>
import gql from 'graphql-tag';

import { Providers } from '@/api-svc-types';

const INITIAL_PREF_BANK_ACCOUNT = {
  id: 'new',
  source: 'QuickBooks',
  name: 'Create New: Bitwave - Digital Assets',
  code: 'new',
  enabled: false,
  type: 'Asset',
};

const INITIAL_PREF_EXPENSE_ACCOUNT = {
  id: 'new',
  source: 'QuickBooks',
  name: 'Create New: Bitwave - Crypto Fees',
  code: 'new',
  enabled: false,
  type: 'Expense',
};

export default {
  props: {
    connectionId: {
      default: null,
      type: String,
    },
    title: {
      default: null,
      type: String,
    },
    refresh: {
      default: null,
      type: Function,
    },
    source: {
      default: null,
      type: String,
    },
    btnName: {
      default: '_finishSetup',
      type: String,
    },
    btnOutline: {
      default: false,
      type: Boolean,
    },
    btnClass: {
      default: '',
      type: String,
    },
    btnColor: {
      default: '',
      type: String,
    },
    btnIcon: {
      default: null,
      type: String,
    },
    connectionData: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      preferredBankAccount: this.connectionData?.accountCode ?? INITIAL_PREF_BANK_ACCOUNT.id,
      preferredExpenseAccount: this.connectionData?.feeAccountCode ?? INITIAL_PREF_EXPENSE_ACCOUNT.id,
      setingUpConnection: true,
      remoteCategories: [],
      errors: [],
      newAccountCode: this.connectionData?.newAccountCode ?? null,
      newFeeAccountCode: this.connectionData?.newFeeAccountCode ?? null,
      provider: this.connectionData?.provider ?? null,
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.resetForm();
      }
    },
    connectionData(prev, next) {
      this.resetForm();
    },
  },
  apollo: {
    remoteCategories: {
      query: gql`
        query GetRemoteCategories($orgId: ID!, $connectionId: ID!) {
          remoteCategories(orgId: $orgId, connectionId: $connectionId) {
            id
            source
            name
            code
            enabled
            type
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
          connectionId: this.connectionId,
        };
      },
      loadingKey: 'isLoading',
    },
  },
  computed: {
    isValid() {
      if (this.source === 'Xero') {
        let pass = true;
        if (this.preferredBankAccount === 'new') {
          pass = pass && this.newAccountCode !== null && this.newAccountCode !== '';
        }
        if (this.preferredExpenseAccount === 'new') {
          pass = pass && this.newFeeAccountCode !== null && this.newFeeAccountCode !== '';
        }
        return pass;
      } else {
        return true;
      }
    },
    expenseAccounts() {
      const arr = [];

      if (!this.connectionData) {
        arr.push(INITIAL_PREF_EXPENSE_ACCOUNT);
      }

      this.remoteCategories.forEach((category) => {
        if (category.type === 'Expense') {
          arr.push({ ...category, name: this.categoryTitle(category) });
        }
      });
      return arr;
    },
    assetAccounts() {
      const arr = [];

      if (!this.connectionData) {
        arr.push(INITIAL_PREF_BANK_ACCOUNT);
      }

      this.remoteCategories.forEach((category) => {
        if (category.type === 'Asset' || category.type === 'Bank') {
          arr.push({ ...category, name: this.categoryTitle(category) });
        }
      });

      return arr;
    },
  },
  methods: {
    hideDialog() {
      this.dialog = false;
    },
    resetForm() {
      // this.preferredBankAccount = this.connectionData.accountCode ?? INITIAL_PREF_BANK_ACCOUNT.id;
      // this.preferredExpenseAccount = this.connectionData.feeAccountCode ?? INITIAL_PREF_EXPENSE_ACCOUNT.id;
      this.setingUpConnection = false;
      this.errors = [];
    },
    async save() {
      this.setingUpConnection = true;
      const resp = await this.$apollo.mutate({
        // Query
        mutation: gql`
          mutation setupConnection(
            $orgId: ID!
            $connectionId: ID!
            $accountCode: String!
            $feeAccountCode: String!
            $newAccountCode: String
            $newFeeAccountCode: String
          ) {
            setupConnection(
              orgId: $orgId
              connectionId: $connectionId
              accountCode: $accountCode
              feeAccountCode: $feeAccountCode
              newAccountCode: $newAccountCode
              newFeeAccountCode: $newFeeAccountCode
            ) {
              success
              errors
            }
          }
        `,
        // Parameters
        variables: {
          orgId: this.$store.state.currentOrg.id,
          connectionId: this.connectionId,
          accountCode: this.preferredBankAccount,
          feeAccountCode: this.preferredExpenseAccount,
          newAccountCode: this.newAccountCode,
          newFeeAccountCode: this.newFeeAccountCode,
        },
      });
      if (resp.data.setupConnection.success) {
        this.hideDialog();
        this.refresh();
      } else {
        resp.data.setupConnection.errors.forEach((error) => {
          this.errors.push({ message: error });
        });
      }
    },
    categoryTitle(category) {
      return `(${category.code}) ${category.name}`;
    },
    readPreviousValues() {
      if (!this.connectionData) return;
      const { feeAccountCode, accountCode, newAccountCode, newFeeAccountCode, provider } = this.connectionData;
      // console.log('readPreviousValues', accountCode, feeAccountCode, newAccountCode, newFeeAccountCode, provider);
      if (provider === Providers.QuickBooks) {
        return `Bank Account: ${accountCode} | Expense Account: ${feeAccountCode}`;
      } else if (provider === Providers.Xero) {
        return `Account Code: ${newAccountCode} | Fee Account Code: ${newFeeAccountCode}`;
      }
    },
  },
};
</script>
<style>
div.first-element-bold div.v-list > div:first-child div.v-list__tile__content div.v-list__tile__title {
  font-weight: bold !important;
}
</style>

<template>
  <ba-dialog
    :save="save"
    :title="$t('_connectFireblocks')"
    :canSave="!canSave"
    :show-button="false"
    :show="showDialog"
    :onHide="hideDialog"
  >
    <v-container fluid grid-list-xl>
      <v-layout wrap>
        <v-flex xs12 lg6>
          <p>
            We recommend that the API credentials you are sharing with us be
            <b>read-only</b>. In addition, we recommend whitelisting our IP.
          </p>
          <v-text-field :label="$t('_apiKey')" v-model="apiKey"></v-text-field>
          <v-textarea :label="$t('_privateKey')" v-model="privateKey"></v-textarea>
          <v-autocomplete
            v-model="exchangeContactId"
            :items="contacts"
            :label="$t('_exchangeContact')"
            item-text="name"
            item-value="id"
            persistent-hint
          ></v-autocomplete>
          <v-btn :disabled="!canValidate" @click="validate" :loading="isValidating">{{ $t('_validate') }}</v-btn>
        </v-flex>

        <v-flex xs6 v-if="canValidate">
          <validation-summary
            ref="connectionValidation"
            :validation-data="validationData"
            account-type="Fireblocks Connection"
          />
        </v-flex>
      </v-layout>

      <v-layout v-if="errorMessage" wrap align-center>
        <v-flex xs12 class="tw-text-red-400">
          {{ errorMessage }}
        </v-flex>
      </v-layout>
    </v-container>
  </ba-dialog>
</template>

<script>
import gql from 'graphql-tag';

import ValidationSummary from '@/components/validation/AccountValidationSummary';

export default {
  components: { ValidationSummary },
  props: ['refresh'],
  data() {
    return {
      apiKey: '',
      privateKey: '',
      showDialog: false,
      exchangeContactId: '',
      errorMessage: null,
      validationData: null,
      isValid: false,
      isValidating: false,
    };
  },

  computed: {
    canValidate() {
      return this.apiKey && this.privateKey;
    },
    canSave() {
      return this.apiKey && this.privateKey && this.exchangeContactId && this.isValid && !this.isValidating;
    },
    contacts() {
      return this.$store.getters['contacts/ENABLED_CONTACTS'];
    },
  },

  watch: {
    showDialog() {
      if (this.showDialog) {
        this.resetForm();
      }
    },
    apiKey() {
      this.isValid = false;
    },
    privateKey() {
      this.isValid = false;
    },
  },

  methods: {
    resetForm() {
      this.apiKey = '';
      this.exchangeContactId = '';
      this.privateKey = '';
      this.errorMessage = null;
      this.isValidating = false;
      this.isValid = false;
    },
    hideDialog() {
      this.showDialog = false;
      this.resetForm();
    },
    show() {
      this.showDialog = true;
    },
    async validate() {
      if (!this.canValidate) {
        return;
      }
      try {
        this.isValidating = true;
        const vars = {
          createFireblocks: {
            apiKey: this.apiKey,
            privateKey: this.privateKey,
            exchangeContactId: this.exchangeContactId,
          },
        };
        const validation = await this.$refs.connectionValidation.validate(vars);
        this.isValid = validation && validation.success;
        this.isValidating = false;
      } catch (error) {
        this.isValid = false;
        this.isValidating = false;
      }
    },

    async save() {
      // reset error message
      this.errorMessage = null;

      const vars = {
        orgId: this.$store.state.currentOrg.id,
        create: {
          createFireblocks: {
            apiKey: this.apiKey,
            exchangeContactId: this.exchangeContactId,
            privateKey: this.privateKey,
          },
        },
      };
      return await this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation CreateConnection($orgId: ID!, $create: CreateConnectionInput!) {
              createConnection(orgId: $orgId, create: $create)
            }
          `,
          // Parameters
          variables: vars,
        })
        .then(() => {
          if (this.errorMessage) {
            return { error: this.errorMessage };
          } else {
            this.resetForm();

            if (this.refresh) {
              this.refresh();
            }

            this.showDialog = false;
          }
        })
        .catch((err) => {
          const errors = err.graphQLErrors.map((e) => e.message);
          this.errorMessage = '* ' + errors[0];
        });
    },
  },
};
</script>




































































































































import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';

import { baConfig } from '../../../config';
import { ConnectionsApi } from '../../../generated/api-svc';
import { AccountValidationResponse } from './accountValidationResponse';

@Component({})
export default class ValidationSummary extends BaseVue {
  public validationData: AccountValidationResponse | null = null;

  @Prop({ default: 'Unknown' })
  public readonly accountType?: string;

  public isLoading = false;

  public show = false;

  public async validate(args: any) {
    this.show = true;
    this.isLoading = true;
    try {
      const connectionsApi = new ConnectionsApi(undefined, baConfig.getFriendlyApiUrl());
      const resp = await connectionsApi.validateConnection(this.$store.state.currentOrg.id, args, {
        withCredentials: true,
      });
      if (resp.status === 200) {
        this.validationData = resp.data;
      } else {
        this.validationData = {
          success: false,
          error: 'Problem Validating: ' + resp.status + ' ' + (resp.data as any).message,
        };
      }
    } catch (e: any) {
      this.validationData = {
        success: false,
        error: 'Problem Validating: ' + e.message,
      };
    } finally {
      this.isLoading = false;
    }

    return this.validationData;
  }

  public reset() {
    this.isLoading = false;
    this.show = false;
    this.validationData = null;
  }
}

<template>
  <ba-dialog
    :save="save"
    :title="$t('_connectPrimeTrust')"
    :canSave="!canSave"
    :show-button="false"
    :show="showDialog"
    :onHide="hideDialog"
  >
    <v-container fluid grid-list-xl>
      <v-layout wrap align-center>
        <v-flex xs6 sm6 md6>
          <p>
            We recommend that the Account credentials you are sharing with us be
            <b>read-only</b>.
          </p>
          <v-checkbox label="Use JWT for Authentication" v-model="useJwt" />

          <div v-if="useJwt === true">
            <v-flex xs12 sm12 md12>
              <v-text-field label="jwt" v-model="jwt"></v-text-field>
              <v-text-field label="PrimeTrust Account Id" v-model="accountId"></v-text-field>
              <v-autocomplete
                v-model="exchangeContactId"
                :items="contacts"
                :label="$t('_exchangeContact')"
                item-text="name"
                item-value="id"
                persistent-hint
              ></v-autocomplete>
            </v-flex>
          </div>

          <div v-if="useJwt === false">
            <v-flex xs12 sm12 md12>
              <v-text-field label="Username" v-model="user"></v-text-field>
              <v-text-field label="Password" v-model="password"></v-text-field>
              <v-text-field label="PrimeTrust Account Id" v-model="accountId"></v-text-field>
              <v-autocomplete
                v-model="exchangeContactId"
                :items="contacts"
                :label="$t('_exchangeContact')"
                item-text="name"
                item-value="id"
                persistent-hint
              ></v-autocomplete>
            </v-flex>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </ba-dialog>
</template>

<script>
import gql from 'graphql-tag';
export default {
  props: ['refresh'],
  data() {
    return {
      user: '',
      password: '',
      accountId: '',
      exchangeContactId: '',
      showDialog: false,
      useJwt: false,
      jwt: '',
    };
  },
  computed: {
    canSave() {
      const canSave = Boolean(
        ((this.useJwt === false && this.user && this.password) || (this.useJwt && this.jwt)) &&
          this.accountId &&
          this.exchangeContactId
      );
      return canSave;
    },
    contacts() {
      return this.$store.getters['contacts/ENABLED_CONTACTS'];
    },
  },
  watch: {
    showDialog() {
      if (this.showDialog) {
        this.resetForm();
      }
    },
    useJwt() {
      if (this.useJwt) {
        this.user = '';
        this.password = '';
      } else {
        this.jwt = '';
      }
    },
  },
  methods: {
    resetForm() {
      this.user = '';
      this.password = '';
      this.accountId = '';
      this.exchangeContactId = '';
      this.useJwt = false;
      this.jwt = '';
    },
    hideDialog() {
      this.showDialog = false;
    },
    show() {
      this.showDialog = true;
    },
    save() {
      let createPrimeTrust = {
        user: this.user,
        password: this.password,
        accountId: this.accountId,
        exchangeContactId: this.exchangeContactId,
      };
      if (this.useJwt) {
        createPrimeTrust = {
          useJwt: this.useJwt,
          jwt: this.jwt,
          accountId: this.accountId,
          exchangeContactId: this.exchangeContactId,
        };
      }

      const variables = {
        orgId: this.$store.state.currentOrg.id,
        create: {
          createPrimeTrust,
        },
      };

      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation CreateConnection($orgId: ID!, $create: CreateConnectionInput!) {
              createConnection(orgId: $orgId, create: $create)
            }
          `,
          // Parameters
          variables,
        })
        .then(() => {
          this.resetForm();
          if (this.refresh) {
            this.refresh();
          }
          this.showDialog = false;
        });
    },
  },
};
</script>

<template>
  <ba-dialog
    :save="save"
    :title="$t('_connectCoinbasePrime')"
    :canSave="!canSave"
    :show-button="false"
    :show="showDialog"
    :onHide="hideDialog"
  >
    <v-container fluid grid-list-xl>
      <v-layout wrap>
        <v-flex xs6 sm6 md6>
          <p>
            We recommend that the API credentials you are sharing with us be
            <b>read-only</b>.
          </p>
          <v-text-field label="Name (Optional)" v-model="name"></v-text-field>
          <v-select
            label="Subsidiary"
            :items="subs"
            v-model="subsidiaryId"
            hint="Subsidiary"
            item-text="name"
            item-value="id"
            v-if="checkFeatureFlag('subsidiaries')"
          ></v-select>
          <v-text-field label="Access Key" v-model="apiKey"></v-text-field>
          <v-text-field label="Passphrase" v-model="passphrase"></v-text-field>
          <v-text-field label="Signing Key" v-model="signingKey"></v-text-field>
          <v-autocomplete
            v-model="exchangeContactId"
            :items="contacts"
            :label="$t('_exchangeContact')"
            item-text="name"
            item-value="id"
            persistent-hint
          ></v-autocomplete>
          <v-btn :disabled="!canValidate" @click="validate" :loading="isValidating">Validate</v-btn>
        </v-flex>
        <v-flex xs6>
          <validation-summary
            ref="connectionValidation"
            :validation-data="validationData"
            account-type="CoinbasePrime Connection"
          />
        </v-flex>
      </v-layout>
    </v-container>
  </ba-dialog>
</template>

<script>
import gql from 'graphql-tag';

import ValidationSummary from '@/components/validation/AccountValidationSummary';
export default {
  components: { ValidationSummary },
  props: ['refresh', 'subs'],
  data() {
    return {
      apiKey: '',
      passphrase: '',
      signingKey: '',
      showDialog: false,
      exchangeContactId: '',
      isValidating: false,
      validationData: null,
      validationError: undefined,
      isValid: false,
      connectionSubsidiary: undefined,
      name: '',
      subsidiaryId: undefined,
    };
  },
  computed: {
    canValidate() {
      return this.apiKey && this.passphrase && this.signingKey && this.exchangeContactId;
    },
    canSave() {
      return this.isValid;
    },
    contacts() {
      return this.$store.getters['contacts/ENABLED_CONTACTS'];
    },
  },
  watch: {
    showDialog() {
      if (this.showDialog) {
        this.resetForm();
        this.$refs.connectionValidation.reset();
      }
    },
  },
  methods: {
    resetForm() {
      this.apiKey = '';
      this.name = '';
      this.passphrase = '';
      this.signingKey = '';
      this.exchangeContactId = '';
      this.subsidiaryId = undefined;
    },
    hideDialog() {
      this.showDialog = false;
    },
    show() {
      this.showDialog = true;
    },
    async validate() {
      this.isValidating = true;
      const vars = this.getCreateVars();
      const validation = await this.$refs.connectionValidation.validate(vars);
      this.isValid = validation.success;
      this.isValid = true;
      // Can we grab the account id and use that on the name if needed
      this.isValidating = false;
    },
    getCreateVars() {
      return {
        subsidiaryId: this.subsidiaryId === '' ? undefined : this.subsidiaryId,
        createCoinbasePrime: {
          apiKey: this.apiKey,
          passphrase: this.passphrase,
          signingKey: this.signingKey,
          exchangeContactId: this.exchangeContactId,
        },
      };
    },
    getVars() {
      return {
        orgId: this.$store.state.currentOrg.id,
        create: this.getCreateVars(),
      };
    },
    async save() {
      this.loading = true;
      try {
        const vars = {
          orgId: this.$store.state.currentOrg.id,
          create: {
            subsidiaryId: this.subsidiaryId === '' ? undefined : this.subsidiaryId,
            name: this.name === '' ? undefined : this.name,
            createCoinbasePrime: {
              apiKey: this.apiKey,
              passphrase: this.passphrase,
              signingKey: this.signingKey,
              exchangeContactId: this.exchangeContactId,
            },
          },
        };

        await this.$apollo.mutate({
          // Query
          mutation: gql`
            mutation CreateConnection($orgId: ID!, $create: CreateConnectionInput!) {
              createConnection(orgId: $orgId, create: $create)
            }
          `,
          // Parameters
          variables: vars,
        });
        this.resetForm();
        if (this.refresh) {
          this.refresh();
        }
        this.showDialog = false;
      } catch (e) {
        this.showErrorSnackbar('Problem saving connection: ' + e.message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
